
import { ProjectMixin, RoutesMixin, TranslationMixin } from '~/mixins'

export default {
  name: 'SectionCardGridItem',
  props: {
    item: {
      type: Object,
      required: true,
      image: {
        type: Object,
        required: true
      },
      page: {
        type: Object,
        translation: {
          type: Object,
          required: true,
          slug: {
            type: String,
            required: true,
            name: {
              type: String,
              required: true
            }
          }
        }
      }
    }
  },
  mixins: [ProjectMixin, RoutesMixin, TranslationMixin]
}
