import { render, staticRenderFns } from "./section__card-grid.vue?vue&type=template&id=1846114e&lang=pug&"
import script from "./section__card-grid.vue?vue&type=script&lang=js&"
export * from "./section__card-grid.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionCardGridItem: require('/co2unt/services/customer-app/components/section/section__card-grid/section__card-grid__item.vue').default})
